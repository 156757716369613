import { ThemeConfig } from 'antd';
import styleVariables from 'styles/_export.module.scss';

const theme: ThemeConfig = {
  token: {
    colorPrimary: styleVariables.primary,
    colorSuccess: styleVariables.success,
    colorError: styleVariables.error,
    colorWarning: styleVariables.warning,
    colorInfo: styleVariables.info,
    colorLink: styleVariables.primary,
    borderRadius: 0,
    colorTextHeading: styleVariables.primary,
  },
  components: {
    Typography: {
      titleMarginBottom: '0px',
      titleMarginTop: '0px',
    },
    Layout: {
      headerBg: styleVariables.surface,
      siderBg: styleVariables.surface,
      triggerBg: styleVariables['primary-bg'],
      triggerColor: styleVariables.primary,
      triggerHeight: 40,
    },
    Menu: {
      radiusItem: 0,
      itemMarginInline: 0,
    },
  },
};

export default theme;
