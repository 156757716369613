import { MsalAuthenticationResult } from '@azure/msal-react';
import { Collapse, Result } from 'antd';
import React from 'react';

export const ErrorComponent: React.FunctionComponent<MsalAuthenticationResult> = ({ error }) => {
  if (!error) {
    return <></>;
  }
  return (
    <Result
      status="error"
      title="Unable to login"
      subTitle={error.errorCode}
      extra={<Collapse bordered={false} key="error" size="small" items={[{ key: '1', label: 'Details', children: <p>{error.message}</p> }]} />}
    />
  );
};
