import { MainHeader, SideNav, loginRequest } from '@/lib';
import { InteractionStatus } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { Layout, Skeleton } from 'antd';
import { Suspense } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './layout.module.scss';

export const BaseLayout = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { instance, inProgress } = useMsal();

  const account = useAccount();

  const onLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  const onLogout = () => {
    instance.logoutRedirect({
      account: account,
      postLogoutRedirectUri: '/',
    });
  };

  return (
    <Skeleton loading={inProgress === InteractionStatus.HandleRedirect}>
      <Layout className={styles.main}>
        <header className={styles.header}>
          <MainHeader onLogin={onLogin} onLogout={onLogout} isAuthenticated={!!account} />
        </header>
        <aside className={styles.sider}> {account ? <SideNav selectedKey={location.pathname} onSelect={navigate} /> : null}</aside>
        <main className={styles.content}>
          <Suspense fallback={<Skeleton active loading />}>
            <Outlet />
          </Suspense>
        </main>
      </Layout>
    </Skeleton>
  );
};
