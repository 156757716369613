import { LeftOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import React, { PropsWithChildren, ReactNode } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { PageHeader } from './pageHeader';
import styles from './pageItems.module.scss';

interface BackLink {
  text: string;
  to: To;
}
interface PageProps extends PropsWithChildren {
  title?: ReactNode;
  headerItems?: ReactNode[];
  backLink?: BackLink;
}

export const Page: React.FunctionComponent<PageProps> = ({ title, children, headerItems, backLink }) => {
  const navigate = useNavigate();

  const goBack = () => navigate(backLink?.to || '/');
  return (
    <>
      {backLink && (
        <Button type="link" icon={<LeftOutlined />} onClick={goBack} className={styles.back}>
          {backLink.text}
        </Button>
      )}
      <PageHeader title={title}>{headerItems}</PageHeader>
      <section className={styles.pageContent}>
        <Flex vertical gap={8}>
          {children}
        </Flex>
      </section>
    </>
  );
};
