import { Col, Row, Typography } from 'antd';
import React, { PropsWithChildren, ReactNode } from 'react';
import styles from './pageItems.module.scss';

interface PageWidgetProps extends PropsWithChildren {
  title?: ReactNode;
  extra?: ReactNode[];
  padding?: string | number;
}

export const PageWidget: React.FunctionComponent<PageWidgetProps> = ({ title, extra, children, padding }) => {
  return (
    <div className={styles.widget} style={{ padding: padding }}>
      <section className={styles.widgetHeader}>
        <Row gutter={16}>
          <Col flex={1}>
            <Typography.Title level={3} type="secondary">
              {title}
            </Typography.Title>
          </Col>
          {extra && extra.map((x, i) => <Col key={i}>{x}</Col>)}
        </Row>
      </section>
      <section className={styles.widgetContent}>{children}</section>
    </div>
  );
};
