import { useGetAggregateGewasReport, useGetAggregateOtherReport, useGetCompaniesReport, useGetBevoegdGezagsByTypeId } from '@/lib/queries/reports';
import { AggregatieRapportInput } from '@/lib/types';
import { downloadBlob } from '@/lib/utils/downloadBlob';
import { Button, Col, DatePicker, Form, Row } from 'antd';
import { Select } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const REPORT_TYPES = {
  GEWAS: 'gewas',
  ALL_COMPANIES: 'allebedrijven',
  GEMEENTE: 'gemeente',
  WATERSCHAP: 'waterschap',
  LANDELIJK: 'landelijk',
};

interface Selection {
  year?: number;
  type?: string;
  bevoegdGezagTypeId?: number;
}

export const ReportSelect: React.FunctionComponent = () => {
  const { t } = useTranslation('reports');
  const [form] = Form.useForm();
  const [year, setYear] = useState<Dayjs | null>(dayjs().subtract(1, 'year'));
  const [type, setType] = useState<string | null>(null);
  const [bevoegdGezagType, setBevoegdGezagType] = useState<number | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selection, setSelection] = useState<Selection>({});
  const [bevoegdGezag, setBevoegdGezag] = useState<AggregatieRapportInput>({});

  const { isLoading: isLoadingAggGewas, data: dataAggGewas } = useGetAggregateGewasReport(
    selection.year,
    !!selection.year && selection.type === REPORT_TYPES.GEWAS
  );
  const { isLoading: isLoadingAggOther, data: dataAggOther } = useGetAggregateOtherReport(
    bevoegdGezag,
    !!selection.year && selection.type !== REPORT_TYPES.GEWAS && selection.type !== REPORT_TYPES.ALL_COMPANIES
  );
  const { isLoading: isLoadingAllCompanies, data: dataAllCompanies } = useGetCompaniesReport(
    selection.year,
    !!selection.year && selection.type === REPORT_TYPES.ALL_COMPANIES
  );
  const { isLoading: isLoadingBevoegdGezagen, data: dataBevoegdGezagen } = useGetBevoegdGezagsByTypeId(
    type === REPORT_TYPES.GEMEENTE ? 1 : type === REPORT_TYPES.WATERSCHAP ? 2 : type === REPORT_TYPES.LANDELIJK ? 3 : undefined,
    type === REPORT_TYPES.GEMEENTE || type === REPORT_TYPES.WATERSCHAP
  );

  useEffect(() => {
    if (dataAggGewas && selection.type === REPORT_TYPES.GEWAS) {
      downloadBlob(dataAggGewas, `aggregate-gewas_${selection.year}.xlsx`);
    } else if (dataAllCompanies && selection.type === REPORT_TYPES.ALL_COMPANIES) {
      downloadBlob(dataAllCompanies, `bedrijven_rapportage_${selection.year}.xlsx`);
    } else if (dataAggOther && selection.type !== REPORT_TYPES.GEWAS && selection.type !== REPORT_TYPES.ALL_COMPANIES) {
      downloadBlob(dataAggOther, `aggregate-${selection.type}_${selection.year}.xlsx`);
    }
  }, [selection, dataAggGewas, dataAllCompanies, dataAggOther]);

  const onSubmit = (year: number, type: string, bevoegdGezagTypeId: number) => {
    setBevoegdGezag({ registratieJaar: year, bevoegdGezagId: bevoegdGezagTypeId, bevoegdGezagTypeCode: type });
    setSelection({ year, type, bevoegdGezagTypeId });
    setIsButtonDisabled(true);
  };

  const onSearchFormFinish = (values: { year?: Dayjs; type?: string; bevoegdGezagId?: number }) => {
    if (!values.year || !values.type) {
      return false;
    }

    onSubmit(values.year?.year(), values.type, values.bevoegdGezagId!);
  };

  useEffect(() => {
    if (dataBevoegdGezagen?.length === 1) {
      // Select the first element if there is only one
      const singleOption = dataBevoegdGezagen[0];
      setBevoegdGezagType(singleOption.id);
      form.setFieldsValue({ bevoegdGezagId: singleOption.id });
    }
  }, [dataBevoegdGezagen, form]);

  useEffect(() => {
    setIsButtonDisabled(!year || !type || (type === 'gemeente' && !bevoegdGezagType));
  }, [year, type, bevoegdGezagType]);

  return (
    <Form onFinish={onSearchFormFinish} form={form} initialValues={{ year: dayjs().subtract(1, 'year') }}>
      <Row gutter={8}>
        <Col>
          <Form.Item name="year" noStyle>
            <DatePicker picker="year" onChange={date => setYear(date)} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="type" noStyle>
            <Select
              placeholder="Rapport type"
              style={{ width: '200px' }}
              options={[
                { value: 'gewas', label: <span>Aggregatie gewas</span> },
                { value: 'gemeente', label: <span>Aggregatie gemeente</span> },
                { value: 'landelijk', label: <span>Aggregatie landelijk</span> },
                { value: 'waterschap', label: <span>Aggregatie waterschap</span> },
                { value: 'allebedrijven', label: <span>Alle bedrijven</span> },
              ]}
              onChange={type => {
                setType(type);
                setBevoegdGezagType(null);
                form.setFieldsValue({ bevoegdGezagId: null });
              }}
            />
          </Form.Item>
        </Col>
        {(type === 'gemeente' || type === 'waterschap') && (
          <Col>
            <Form.Item name="bevoegdGezagId" noStyle>
              <Select
                disabled={isLoadingBevoegdGezagen}
                loading={isLoadingBevoegdGezagen}
                placeholder={`Selecteer een ${type}`}
                style={{ width: '300px' }}
                options={dataBevoegdGezagen
                  ?.sort((a, b) => a.volledigeNaam!.localeCompare(b.volledigeNaam!))
                  .map(bg => ({ value: bg.id, label: <span>{bg.volledigeNaam}</span> }))}
                onChange={type => {
                  setBevoegdGezagType(type);
                }}
              />
            </Form.Item>
          </Col>
        )}
        <Col>
          <Button htmlType="submit" type="primary" loading={isLoadingAggGewas || isLoadingAggOther || isLoadingAllCompanies} disabled={isButtonDisabled}>
            {t('actions.submit')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};