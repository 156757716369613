import Logo from '@/assets/logo.jpg';
import { UserAvatar } from '@/lib/components/core/auth/userAvatar';
import { useUserContext } from '@/lib/context/userContext';
import { UserOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Feature } from '../features/feature';
import styles from './core.module.scss';
import { useNavigate } from 'react-router-dom';
const { Header } = Layout;

interface MainHeaderProps {
  onLogin: () => void;
  onLogout: () => void;
  isAuthenticated: boolean;
}

export const MainHeader: React.FunctionComponent<MainHeaderProps> = ({ onLogin, onLogout, isAuthenticated }) => {
  const { t } = useTranslation('core');
  const { username } = useUserContext();
  const navigate = useNavigate();

  const onPrivacy = () => {
    navigate('/privacy');
  };

  return (
    <Header className={styles.header}>
      <div className={styles.logo}>
        <img src={Logo}></img>
      </div>

      {isAuthenticated ? (
        <div>
          <Button type="default" style={{ marginRight: 10 }} onClick={onPrivacy}>
            {t('userMenu.privacy')}
          </Button>
          <UserAvatar
            user={username}
            items={[
              {
                key: 999,
                label: t('userMenu.logout'),
                icon: <UserOutlined />,
                onClick: onLogout,
              },
            ]}
          />
        </div>
      ) : (
        <div>
          <Button type="default" style={{ marginRight: 10 }} onClick={onPrivacy}>
            {t('userMenu.privacy')}
          </Button>
          <Feature flag="show-maintenance-mode">
            <Button type="primary" onClick={onLogin}>
              {t('userMenu.login')}
            </Button>
          </Feature>
        </div>
      )}
    </Header>
  );
};
