import { PageWidget } from '@/lib';
import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Authenticator from '@/assets/authenticator-app.png';
import QRAuthenticator from '@/assets/authenticator-qr.png';
import CodeAuthenticator from '@/assets/authenticator-code.png';

export const MaintenanceWidget: React.FunctionComponent = () => {
  const { t } = useTranslation('core');
  return (
    <div style={{ maxWidth: '800px' }}>
      <PageWidget padding={'1rem'}>
        <Typography.Title level={1}>{t('maintenance.title')}</Typography.Title>
        <Typography.Paragraph>{t('maintenance.introParagraph')}</Typography.Paragraph>
        <Typography.Paragraph>{t('maintenance.larsDetails')}</Typography.Paragraph>
        <Typography.Paragraph>{t('maintenance.authDetails')}</Typography.Paragraph>
        <img src={Authenticator} style={{ maxWidth: '300px', margin: 'auto' }}></img>
        <Typography.Paragraph>{t('maintenance.qrDetails')}</Typography.Paragraph>
        <img src={QRAuthenticator} style={{ maxWidth: '400px', margin: 'auto' }}></img>
        <Typography.Paragraph>{t('maintenance.codeDetails')}</Typography.Paragraph>
        <img src={CodeAuthenticator} style={{ maxWidth: '200px', margin: 'auto' }}></img>
      </PageWidget>
    </div>
  );
};
