import { Typography } from 'antd';
import React, { PropsWithChildren } from 'react';
import styles from './pageItems.module.scss';

interface PageHeaderProps extends PropsWithChildren {
  title?: React.ReactNode;
}

export const PageHeader: React.FunctionComponent<PageHeaderProps> = ({ title, children }) => {
  return (
    <div className={styles.header}>
      {title ? (
        <Typography.Title level={2} color="primary">
          {title}
        </Typography.Title>
      ) : null}
      {children}
    </div>
  );
};
