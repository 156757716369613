import { Feature } from '@/lib/components/core/features/feature';
import { MaintenanceWidget } from '@/lib/components/core/maintenanceWidget';
import React from 'react';
import styles from './page.module.scss';
export const Home: React.FunctionComponent = () => {
  return (
    <>
      <section className={styles['s-home']}>
        <div className={styles['s-home-intro']}>
          <Feature flag="show-maintenance-mode">
            <MaintenanceWidget />
          </Feature>
        </div>
        <div className={styles['s-home-news']}></div>
        <div className={styles['s-home-users']}></div>
        <div className={styles['s-home-faq']}></div>
      </section>
    </>
  );
};
