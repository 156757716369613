import { useAccount } from '@azure/msal-react';
import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';

interface Context {
  username?: string;
  roles: string[];
}

const UserContext = createContext<Context>({
  roles: [],
});

export const useUserContext = (): Context => React.useContext(UserContext);

export const UserProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [roles, setRoles] = useState<string[]>([]);
  const account = useAccount();

  useEffect(() => {
    if (account) {
      const roleString = account.idTokenClaims?.extension_Roles as string;
      const roles = roleString ? roleString.split(',') : [];
      setRoles(roles);
    } else {
      setRoles([]);
    }

    return () => setRoles([]);
  }, [account]);

  return (
    <UserContext.Provider
      value={{
        username: account?.username,
        roles: roles,
      }}>
      {children}
    </UserContext.Provider>
  );
};
